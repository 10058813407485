import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    redirect: '/home'
  },{
    path: '/loading',
    meta: {
      title: '加载页',
    },
    component: () => import('@/views/loading')
  },{
    path: '/home',
    meta: {
      title: '首页',
      backHide: true
    },
    component: () => import('@/views/home')
  },{
    path: '/open',
    meta: {
      title: '首页',
      backHide: true
    },
    component: () => import('@/views/open')
  },{
    path: '/home',
    meta: {
      title: '首页',
      backHide: true
    },
    component: () => import('@/views/home')
  },{
    path: '/chat',
    meta: {
      title: '聊天',
      backHide: true
    },
    component: () => import('@/views/chat')
  }
  ,{
    path: '/demo2',
    meta: {
      title: '聊天',
      backHide: true
    },
    component: () => import('@/views/demo2')
  }
  ,{
    path: '/demo',
    meta: {
      title: '聊天',
      backHide: true
    },
    component: () => import('@/views/demo')
  },{
    path: '/video',
    meta: {
      title: '聊天',
      backHide: true
    },
    component: () => import('@/views/video')
  },{
    path: '/card',
    meta: {
      title: '卡片pk',
      backHide: true
    },
    component: () => import('@/views/card')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
