import Vue from 'vue'
import axios from 'axios'
import router from '../router'
import { Dialog, Toast } from 'vant';
import { encryptDes, decryptDes } from '../api/des'
Vue.prototype.$axios = axios
axios.defaults.timeout = 60000

//   //开发环境下的代理地址，解决本地跨域跨域，配置在config目录下的index.js dev.proxyTable中
// axios.defaults.baseURL = process.env.VUE_APP_API_URL  //正式

function sort_ascii(obj) {
  
  let arr = new Array();
  let num = 0;
  for (let i in obj) {
      arr[num] = i;
      num++;
  }
  let sortArr = arr.sort();
  let str = '';             //自定义排序字符串
  for (let i in sortArr) {
      if(undefined != obj[sortArr[i]] && "" != obj[sortArr[i]]){
          str += sortArr[i] + '=' + obj[sortArr[i]] + '&';
      }
  }
  //去除两侧字符串
  let char = '&';
  str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');
  return str;
}

axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token');
    let tokenHeader = localStorage.getItem('tokenHeader');
    if (token) {
      if (config.url.indexOf('restapi.amap.com')==-1) {
        config.headers[tokenHeader] = String(token);
      }
    }
    // config.params = {
    //   ...config.params,
    //   timestamp:+new Date()
    // }
    // config.params.timestamp = +new Date()
    // let ed = encryptDes(sort_ascii(config.params),'wbXmlEMWc4o=')
    // config.params.signature = ed
    return config;
  },
  err => {
    return Promise.reject(err);
  })

axios.interceptors.response.use(
  response => {  //成功请求到数据
    // //这里根据后端提供的数据进行对应的处理
    if (response.data.code == 200||response.data.code == 0||(!response.data.code&&response.status == 200)) {
      return response
    } else if (response.data.code == 500) {
      Dialog.alert({
        title: '提示',
        message: response.data.message
      })
      return Promise.reject(response);
    } else if (response.data.code == 400) {
      Dialog.alert({
        title: '提示',
        message: response.data.message
      })
      return Promise.reject(response);
    } else if (response.data.code == 401) {
      sessionStorage.removeItem('code')
      console.log('401啦')
      // router.app.$router.replace({path: '/login'})
      return Promise.reject(response);
    }else {
      return Promise.reject(response);
    }
  },
  error => {  //响应错误处理
    Toast.clear()
    Dialog.alert({
      title: '提示',
      message: '请求超时'
    })
    return Promise.reject(error)
  }
)




