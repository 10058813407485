import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import './axios'
import './axios/router'
import VueCupUi from '@/components/vue-cup-ui';
import '@/components/vue-cup-ui/lib/vue-cup-ui.css';
import '@/common/com.scss';
import '@/assets/js/rem.js';

// import VueTouch from 'vue-touch'

Vue.use(Vant);
Vue.use(VueCupUi);
// Vue.use(VueTouch, {name: 'v-touch'})
//引入vconsole
// require('./assets/js/vconsole')
if(process.env.NODE_ENV !== 'production'||process.env.NODE_ENV == 'test'){
  require('./assets/js/vconsole')
}


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
