<template>
  <button class="up-button"
          @click="handleClick">
    <slot></slot>
  </button>
</template>

<script>
  import {callNative} from "../../common/hybrid";

  export default {
    name: "UPButton",
    props: {
      scope: String,
      timeout: Number
    },
    methods: {
      appletExplicitAuth(success, fail) {
        let iCanCall = callNative(function (data) {
          if (typeof success === 'function') {
            success(data);
          }
        }, function (err) {
          if (typeof fail === 'function') {
            let cordovaError;
            if (window.cordova) {
              switch (window.cordova.errorRetStatus) {
                case window.cordova.callbackStatus.INVALID_ACTION:
                  cordovaError = {
                    errcode: 'c03',
                    errmsg: 'INVALID_ACTION_EXCEPTION: 插件里面没有此方法！'
                  }
                  break;

                case window.cordova.callbackStatus.CLASS_NOT_FOUND_EXCEPTION:
                  cordovaError = {
                    errcode: 'c04',
                    errmsg: 'CLASS_NOT_FOUND_EXCEPTION: 此插件没有实现！'
                  }
                  break;

                case window.cordova.callbackStatus.ILLEGAL_ACCESS_EXCEPTION:
                  cordovaError = {
                    errcode: 'c02',
                    errmsg: 'ILLEGAL_ACCESS_EXCEPTION: 无权限访问此插件！'
                  }
                  break;
                default:
                  break;
              }
            }
            if (cordovaError) {
              fail(cordovaError)
            } else {
              fail(err);
            }
          }
        }, 'UPWebSdk', 'appletExplicitAuth', [{scope: this.scope}]);
        if (!iCanCall) {
          setTimeout(() => {
            this._count++;
            if (this._count > (this._timeout / 20)) {
              console.warn('请确定是否运行在云闪付APP中,且成功加载了upsdk.js');
              fail({
                errcode: '__ENV__10001',
                errmsg: '检测到未在云闪付APP中运行或未成功加载upsdk.js'
              })
            } else {
              this.appletExplicitAuth(success, fail)
            }
          }, 20)
        }
      },
      handleClick(event) {
        if (this.btnDisable) return;
        if (this.timeout && isNaN(parseInt(this.timeout))) {
          this.$emit('click', event, {
            errcode: '__ENV__10002',
            errmsg: '检测到timeout值非法'
          });
          return;
        }
        this._count = 0;
        this._timeout = this.timeout || 2000; // 默认2s超时
        this.btnDisable = true; // 防止多次点击，直到回调中才释放
        this.appletExplicitAuth(data => {
          this.btnDisable = false;
          this.$emit('click', event, null, data);
        }, err => {
          this.btnDisable = false;
          this.$emit('click', event, err);
        })
      }
    }
  }
</script>

<style scoped>
  .up-button {
    background: #5A98D2;
    border-radius: 9px;
    width: 62px;
    height: 24px;
    border: none;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    font-family: PingFangSC-Semibold;
  }

  .up-button:focus {
    outline: none;
  }
</style>
