/**
 * Created on 2020/9/3.
 */

const _agent = navigator.userAgent.toLowerCase();

function isFunction(value) {
    return Object.prototype.toString.call(value) === '[object Function]'
}

function truncatedRespForJSBridge(data) {
  if (!data) {
    return data;
  } else if (data.resultParams) {
    return data.resultParams;
  } else if (data.resultString) {
    return data.resultString;
  }
}

export const isJSBridgeEnv = /\(securitywebcache\s([\d\.]+)\)/g.test(_agent);
export const isCordovaEnv = /\(cordova\s([\d\.]+)\)/g.test(_agent);

export function callNative(success, fail, plugin, action, paramArray) {
    let iCanCall = false;
    if (isCordovaEnv && window.cordova) {
        iCanCall = true;
        window.cordova.exec(success, fail, plugin, action, paramArray);
    } else if (isJSBridgeEnv && window.WebViewJavascriptBridge) {
        iCanCall = true;
        window.WebViewJavascriptBridge.callHandler(plugin, action, paramArray, function (data) {
            isFunction(success) && success(truncatedRespForJSBridge(data))
        }, function (err) {
            isFunction(fail) && fail(truncatedRespForJSBridge(err))
        })
    }
    return iCanCall;
}
