import axios from 'axios'
import qs from 'qs'

export function rank(data) {
    return axios({
        url: `/api/record/rank`,
        method: 'get',
        params: data
    })
}

export function sdkAuth(data) {
    return axios({
        url: `/api/user/auth`,
        method: 'get',
        params: data
    })
}

export function getCharacters(data) {
    return axios({
        url: `/api/character/getCharacters`,
        method: 'post',
        data: data
    })
}

//获取id
export function getSessionNew(data) {
    return axios({
        url: `/api/session/new`,
        method: 'post',
        data: data
    })
}

//获取对话
export function getEvent(data) {
    return axios({
        url: `/api/session/event`,
        method: 'post',
        data: data
    })
}

//选中选项
export function getEcho(data) {
    return axios({
        url: `/api/session/echo`,
        method: 'post',
        data: data
    })
}



//绑定银行卡
export function bindBankCard(data) {
    return axios({
        url: `/api/user/bindBankCard`,
        method: 'post',
        params: data
    })
}



//银行卡列表
export function bankCardList(data) {
    return axios({
        url: `/api/user/bankCardList`,
        method: 'post',
        params: data
    })
}


//银行卡识别
export function postBankCardDiscern(file) {
    let param = new FormData(); //创建form对象
    param.append('file',file,file.name);
    return axios({
        url: `/api/sys/bankCard/discern`,
        method: 'post',
        headers: { // 设置请求头
            "Content-Type": "multipart/form-data"
        },
        data: param  // 参数
    })
}



//奖品列表
export function getPrizeList(data) {
    return axios({
        url: `/api/prize/list`,
        method: 'post',
        params: data
    })
}



//抽奖
export function prizeLuckyDraw(data) {
    return axios({
        url: `/api/prize/luckyDraw`,
        method: 'post',
        params: data
    })
}



//任务列表
export function getTaskList(data) {
    return axios({
        url: `/api/task/list`,
        method: 'get',
        params: data
    })
}


//浏览有礼
export function taskBrowse(data) {
    return axios({
        url: `/api/task/browse`,
        method: 'get',
        params: data
    })
}


//问答 - 每日10题
export function postQuestionAnswerProblemList(params) {
    return axios({
        url: `/api/question_answer/problemList`,
        method: 'post',
        params
    })
}

//问答 - 提交
export function postQuestionAnswerSubmit(params) {
    return axios({
        url: `/api/question_answer/submit`,
        method: 'post',
        params
    })
}



//奖品列表
export function getUserPrizeList(params) {
    return axios({
        url: `/api/user_prize/list`,
        method: 'post',
        params
    })
}



//授权
export function authorization(params) {
    return axios({
        url: `/api/prize/authorization`,
        method: 'post',
        params
    })
}





