import router from '../router'
import Vue from 'vue'

router.beforeEach((to, from, next) => {
		
	next();
  });
router.afterEach((to,from) => {
	if (to.meta.backHide) {
		upsdk.setTitleStyle({
			// 可选，导航栏(含状态栏)背景色及透明度。16进制，前2位(8F)透明度，后六位(FFFFFF)颜色，仅对当前页有效，其余页还是申请配置的导航默认颜色。
			navBackgroundColor:'0x8FFFFFFF', 
			appletStyle: 'black', //可选，black-黑色主题，white-白色主题。 
			backBtnVisible: '0', // 可选，左侧返回按钮是否显示。’0’不显示，’1’显示，不传或空则默认显示 
			appletTitleBarVisible: '1', // 可选，标题栏是否显示。’0’不显示，’1’显示，默认显示
			appletTitleGradientOrient:'', // 可选，渐变色方向，支持top、bottom、left、right
			appletTitleGradientStartColor:'',//渐变起始颜色
			appletTitleGradientEndColor:'' //渐变结束颜色
		})
	} else {
			upsdk.setTitleStyle({
			// 可选，导航栏(含状态栏)背景色及透明度。16进制，前2位(8F)透明度，后六位(FFFFFF)颜色，仅对当前页有效，其余页还是申请配置的导航默认颜色。
			navBackgroundColor:'0x8FFFFFFF', 
			appletStyle: 'black', //可选，black-黑色主题，white-白色主题。 
			backBtnVisible: '1', // 可选，左侧返回按钮是否显示。’0’不显示，’1’显示，不传或空则默认显示 
			appletTitleBarVisible: '1', // 可选，标题栏是否显示。’0’不显示，’1’显示，默认显示
			appletTitleGradientOrient:'', // 可选，渐变色方向，支持top、bottom、left、right
			appletTitleGradientStartColor:'',//渐变起始颜色
			appletTitleGradientEndColor:'' //渐变结束颜色
			})
	}
	// aplus_queue.push({
	// 	action: 'aplus.sendPV',
	// 	arguments: [{
	// 		is_auto: false
	// 		}, {
	// 	}]
	// })
	document.body.scrollTop = document.documentElement.scrollTop = 0;
	
	
})