/**
 * Created on 2020/6/22.
 */
import UPButton from './src/button';

UPButton.install = function(Vue) {
  Vue.component(UPButton.name, UPButton);
};

export default UPButton;
