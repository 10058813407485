/**
 * Created on 2020/6/22.
 */
// import './common/resize.js';
import './common/common.less';
import UPButton from '../packages/button/index.js';

const components = [
  UPButton
]

const install = function (Vue, opts = {}) {
  components.forEach(component => {
    if (install.installed) return
    Vue.component(component.name, component)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default {
  version: '0.1.0',
  install,
  UPButton
}
