<template>
    <div id="app">
        <router-view />
        <div class="edition">v1.0.7</div>
    </div>
</template>
<script>
import { sdkAuth } from '@/api';
export default {
    props: {},
    data() {
        return {
            obj:{}
        };
    },
    methods: {
        getUrlParams(url){
            const Params = {}
            if(url.indexOf('?')>0){//判断是否有qurey
                let parmas = url.slice(url.indexOf('?')+1)//截取出query
                const paramlists = parmas.split('&')//分割键值对
                for (const param of paramlists) {
                let a = param.split('=')
                Object.assign(Params,{[a[0]]:a[1]})//将键值对封装成对象
                }
            }
            return Params
        }

    },
    computed: {},
    created(e) {
        let url = window.location.href
        this.obj = this.getUrlParams(url)
        console.log(this.obj,'234234234234')
        if(this.obj&&!this.obj.lastRecordId){
            sessionStorage.setItem('obj',JSON.stringify(this.obj))
        }else{
            sessionStorage.removeItem('obj')
        }


    },
    mounted() {


    },
    watch: {
    },
};
</script>

<style >
.edition {
    position: fixed;
    right: .2rem;
    bottom: 0;
    font-size: .08rem;
    color: rgba(255, 255, 255, 1);
    z-index: 99;
}



#app {
    width: 100vw;
    max-width: 7.5rem;
    min-height: 100vh;
}

* {
    margin: 0;
    padding: 0;
}

.line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

:-moz-placeholder {
    color: #666666 !important;
    opacity: 1;
    font-weight: 400;
}

::-moz-placeholder {
    color: #666666 !important;
    opacity: 1;
    font-weight: 400;
}

input:-ms-input-placeholder {
    color: #666666 !important;
    opacity: 1;
    font-weight: 400;
}

input::-webkit-input-placeholder {
    color: #666666 !important;
    opacity: 1;
    font-weight: 400;
}

input::-moz-input-placeholder {
    color: #666666 !important;
    opacity: 1;
    font-weight: 400;
}

textarea::-webkit-input-placeholder {
    color: #666666;
}
</style>
